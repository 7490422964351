import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"

import { CONFIG } from "@meili/config"
import { isEmpty } from "@meili/utils/dist/helper-methods"

import { pushToTagging } from "analytics/utils"

import { getCountry, getLanguage } from "localisation/utils"

import { useUIMonitor } from "monitoring/UIMonitor"

import type { ListenerType } from "observer/useMutationObserver"
import useMutationObserver from "observer/useMutationObserver"

import { parseDeeplink, parseLocale } from "deeplinks/utils"

import { mliClassNames } from "style/utils"

import root from "root"

import type { MeiliParams } from "./validateParams"
import type { Config } from "./PartnerContentContext"
import { getValidatedMutatedParam, getValidatedParams } from "./validateParams"

interface DeeplinkConfig extends Config {
  REACT_APP_DEEPLINK_URL_LOCALE: string
  REACT_APP_DEEPLINK_URL: string
}

/**
 * Reading iframe URL for params
 */
export function getLocationSearchProps(): { [key: string]: string | null } {
  // We are using hash routing so need to split on ? after the #
  const location = window?.location?.hash
    ? window?.location?.hash?.split("?")?.[1]
    : window?.location?.search

  return parseDeeplink(location)
}

export function setLocale(locale?: string) {
  return locale ? parseLocale(locale) : CONFIG.REACT_APP_DEFAULT_LOCALE
}

/**
 * Initial state is:
 *    - Initialised from CONFIG (ie. ptid),
 *    - Then overwritten with values from root div ("data-" attributes),
 *    - Then overwritten with values from iframe URL (in case integration is using iframe)
 */
function getInitialParams(): MeiliParams {
  const { locale } = root.dataset

  const initialParams = {
    // Can be used as a default setting but mostly for tests
    path: CONFIG.REACT_APP_DATA_PATH,
    ...root?.dataset,
    locale: setLocale(locale),
    ...getLocationSearchProps()
  }

  return {
    ...getValidatedParams(initialParams),
    iframe: window.self !== window.top,
    direction: "ltr"
  }
}

const MeiliParamsContext = createContext<MeiliParams>(getInitialParams())

/**
 * Source of truth for:
 * - query -
 * - path -
 * - fullpage -
 * - locale -
 * - currency -
 * - ptid/pid - for partner identifications, pid still used by some (105)
 * - sid - for deeplinking with same sessionId
 * - cid - for deeplinking with same customerId
 * - direction - for ltr or rtl layout
 */
export function useMeiliParams(): MeiliParams {
  return useContext(MeiliParamsContext)
}

/**
 * Observe mutations in root div and update context (eg if "data-" attributes values have changed)
 * Only need to be called once, in the provider. To access the data, call useMeiliParams()
 */
function useExtractMeiliParams() {
  const initialState = useMeiliParams()
  const [params, setParams] = useState(() => initialState)

  const handleMutation: ListenerType = useCallback((mutation) => {
    const newParams = getValidatedMutatedParam(mutation)
    setParams((oldValue) => ({
      ...oldValue,
      ...newParams,
      errors: {
        ...oldValue.errors,
        ...newParams.errors
      }
    }))
  }, [])
  const { setRef } = useMutationObserver(handleMutation)

  useEffect(() => {
    setRef(root)
  }, [setRef])

  return { params }
}

export function useDeeplinkCheck(
  url: string,
  ptid?: string,
  isDeeplink?: boolean
) {
  const { monitor } = useUIMonitor()

  useEffect(() => {
    if (isDeeplink) {
      const request = new XMLHttpRequest()
      request.open("HEAD", url, true)
      request.onreadystatechange = () => {
        if (request.readyState === 4) {
          if (request.status === 404) {
            // eslint-disable-next-line no-console
            monitor?.captureException(
              new Error(
                `Deeplink URL does not exist. Check partner config - ${ptid}- ${url}.`
              )
            )
          }
        }
      }
      request.send()
    }
  }, [monitor, url, ptid, isDeeplink])
}

/**
 * Generate deeplink for app from config setting
 */
export const deeplinkTemplate = (
  locale?: string,
  config?: Partial<DeeplinkConfig>
) => {
  let url = ""

  if (locale && typeof config?.REACT_APP_DEEPLINK_URL_LOCALE === "object") {
    url =
      config?.REACT_APP_DEEPLINK_URL_LOCALE?.[locale.toLowerCase()] ??
      config?.REACT_APP_DEEPLINK_URL
  } else {
    url = config?.REACT_APP_DEEPLINK_URL ?? ""
  }

  if (url) {
    const localeMap: Record<string, string> = {
      baseUrl: window.location.origin ?? "",
      locale: locale ?? "",
      lang: getLanguage(locale),
      country: getCountry(locale)
    }

    Object.keys(localeMap).forEach((key) => {
      const prop = localeMap[key]
      url = url?.replace(new RegExp(`{{${key}}}`, "g"), prop.toLowerCase())
    })
  }

  return url
}

export function MeiliParamsProvider({
  children
}: {
  readonly children: ReactNode
}) {
  const { params } = useExtractMeiliParams()
  const { t, i18n } = useTranslation()
  const isMountedRef = useRef(true)
  const { monitor } = useUIMonitor()

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  const { locale, ptid } = params

  // set directionality (ltr or rtl)
  params.direction = i18n.dir()

  // updates locale if mutates
  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale)
    }
  }, [locale, i18n])

  // Set ptid in UI Monitor
  useEffect(() => {
    if (ptid) {
      monitor?.setTag("ptid", ptid)
    }
  }, [monitor, ptid])

  const errors = Object.entries(params.errors).filter(
    ([, value]) => value != null
  )
  const hasError = Boolean(errors.length)
  const paramsString = JSON.stringify(params)

  // Send tag if the app is loaded without ptid
  useEffect(() => {
    if (isEmpty(params.ptid)) {
      const href = window?.location?.href ?? "Unknown source"
      const e = new Error(
        `No PTID found in MeiliContext: ${paramsString} from ${href}`
      )
      e.name = "MissingPTID"

      if (CONFIG.REACT_APP_THROW_ERROR_NO_PTID) {
        // throw error & send tag
        throw e
      } else {
        // just send to tagging app and UI monitor
        pushToTagging(params.ptid, "errorBoundary.MissingPTID.times")
        monitor?.captureException(e)
      }
    }
  }, [monitor, params.ptid, paramsString])

  if (hasError) {
    // Error sent to UI monitor via ErrorBoundary in App()
    if (CONFIG.REACT_APP_TO_USE === "Connect") {
      const e = new Error(`${errors.toString()} params: ${paramsString}`)
      e.name = "Params"
      throw e
    }

    return (
      <div className={mliClassNames("params")}>
        <h1>{t("errorMessageHeader")}</h1>
        <p>{t("errorMessageInvalidDataParams")}</p>
      </div>
    )
  }

  return (
    <MeiliParamsContext.Provider value={params}>
      {children}
    </MeiliParamsContext.Provider>
  )
}
