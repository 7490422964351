import { deeplinkBase64ToJSON, parseLocale } from "deeplinks/utils"

type Location = {
  iataCode?: string
  searchCode?: string
}
export type Query = {
  countryCode?: string
  dropoffTime?: string
  driverAge?: number
  dropoffLocation?: Location
  pickupLocation?: Location
  differentPickUpDropOff?: boolean
  dropoffDate?: string
  pickupTime?: string
  pickupDate?: string
  confirmationId?: string
  lastName?: string
  givenName?: string
  email?: string
  prefillOnly?: boolean
}

type MeiliParamsRawValues = {
  query?: Query[]
  path?: string
  fullpage?: string
  locale?: string
  currency?: string
  ptid?: string
  pid?: string
  direction?: string
}

export type MeiliParams = MeiliParamsRawValues & {
  errors: Partial<Record<keyof MeiliParamsRawValues, Error | null>>
  iframe?: boolean
}

function getAttributeValue(attributeName: string, target: HTMLElement) {
  return target.getAttribute(attributeName) ?? undefined
}

function getValidatedQuery(query?: string): Query[] | undefined {
  if (typeof query === "string") {
    const validatedQuery = deeplinkBase64ToJSON(query)

    if (validatedQuery.error) {
      throw validatedQuery.error
    }

    return validatedQuery
  }
  return undefined
}

const validationFuncMap = {
  query: getValidatedQuery
} as const

export function getValidatedParams(
  params: {
    query?: string
  } & Omit<MeiliParamsRawValues, "query">
): MeiliParams {
  const validatedParams: MeiliParams = {
    errors: {}
  }

  Object.keys(params).forEach((keyIteration) => {
    const key = keyIteration as keyof MeiliParamsRawValues
    const value = params[key]

    try {
      if (key === "query") {
        const validateParam = validationFuncMap[key]
        validatedParams[key] = validateParam(value)
        // IMPORTANT PID is still used in LHG confirmation/MMB, so it must be kept
      } else if (key === "pid") {
        validatedParams.ptid = value
      } else validatedParams[key] = value
    } catch (err) {
      validatedParams.errors[key] = err as Error
    }
  })
  return validatedParams
}

export function getValidatedMutatedParam(
  mutation: MutationRecord
): Partial<MeiliParams> {
  if (mutation.attributeName?.startsWith("data-")) {
    const key = mutation.attributeName.replace(
      /^data-/,
      ""
    ) as keyof MeiliParamsRawValues
    const value = getAttributeValue(
      mutation.attributeName,
      mutation.target as HTMLElement
    )

    try {
      // IMPORTANT PID is still used in LHG confirmation/MMB, so it must be kept
      if (key === "pid") {
        return {
          ptid: value,
          errors: { ptid: null }
        }
      }
      if (key === "locale" && value) {
        return {
          locale: parseLocale(value),
          errors: { locale: null }
        }
      }
      return {
        [key]: key === "query" ? validationFuncMap[key](value) : value,
        errors: { [key]: null }
      }
    } catch (err) {
      return { errors: { [key]: err } }
    }
  }
  return { errors: {} }
}
