import { useEffect, useState } from "react"

import { CONFIG } from "@meili/config"

import { useMeiliParams } from "integration/MeiliParamsContext"
import { getLanguage } from "localisation/utils"

interface Response {
  [key: string]: {
    [key: string]: string
  }
}

function usePartnerContent() {
  const { ptid, locale } = useMeiliParams()
  const [content, setContent] = useState({})

  useEffect(() => {
    if (!ptid) {
      return
    }
    const language = getLanguage(locale)
    const [stylePartnerID, styleTouchPointId] = ptid.split(".")

    const renderMessage = (message: string) => {
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(`${message} - ${ptid}`)
      }
    }

    // if language.[key] is defined in content.json, override or use default partner content
    const contentFallback = (response: Response, key: string) =>
      response?.[locale ?? language]?.[key] ??
      response?.[language]?.[key] ??
      response?.default?.[key]

    const fetchData = async () => {
      try {
        // Get rule-based content
        const response = await fetch(
          `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/config/content`,
          {
            method: "GET",
            headers: {
              ptid,
              "Content-type": "application/json",
              "Cache-Control": "no-cache"
            }
          }
        )

        let url = ""
        if (response.status === 200) {
          const contentConfig = await response.json()
          url = `${CONFIG.REACT_APP_CONTENT_API}/${contentConfig.cloudfrontLink}`
        } else {
          // If no rule-based content exists, fallback to default content.json file for the touchpoint
          url = `${CONFIG.REACT_APP_CONTENT_API}/partner_content/${stylePartnerID}/${styleTouchPointId}/content/content.json`
        }

        const contentResponse = await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            "Cache-Control": "no-cache"
          }
        })
        const contentJson = await contentResponse.json()

        const contentWithoutPrefix = {
          ...contentJson.default,
          ...contentJson[language]
        }

        setContent(
          Object.keys(contentWithoutPrefix).reduce(
            (prev, current) => ({
              ...prev,
              [current]: `${CONFIG.REACT_APP_CONTENT_API}/${contentWithoutPrefix[current]}`
            }),
            {}
          )
        )

        const translationUrl = contentFallback(contentJson, "translations")

        if (translationUrl) {
          try {
            const translationsResponse = await fetch(
              `${CONFIG.REACT_APP_CONTENT_API}/${translationUrl}`,
              {
                method: "GET",
                headers: {
                  "Content-type": "application/json",
                  "Cache-Control": "no-cache"
                }
              }
            )
            const translations = await translationsResponse.json()

            setContent((current) => ({
              ...current,
              translations:
                translations[locale ?? language] ??
                translations[language] ??
                translations.default
            }))
          } catch (error) {
            renderMessage(
              `No translation content found for language ${language} for PTID`
            )
          }
        }
      } catch (error) {
        renderMessage("No partner content found for PTID")
      }
    }

    fetchData()
  }, [ptid, locale])

  return content
}

export default usePartnerContent
