import { makeDateTimeForAPI, getHeadersForAPI, checkResponse } from "./utils"
import { SearchAPIEndpoint } from "./endpoints"

function getAvailParams(criteria, currencyCode) {
  // Construct a datetime for the API if there is a date present
  const pickupDateTime = criteria.pickupDate
    ? makeDateTimeForAPI(criteria.pickupDate, criteria.pickupTime)
    : undefined
  const dropoffDateTime = criteria.dropoffDate
    ? makeDateTimeForAPI(criteria.dropoffDate, criteria.dropoffTime)
    : undefined

  return {
    pickupLocation:
      criteria.pickupLocation?.searchCode ??
      // we need to keep iataCode for backwards compatibility
      criteria.pickupLocation?.iataCode ??
      criteria.pickupLocation?.id,
    dropoffLocation:
      criteria.dropoffLocation?.searchCode ??
      // we need to keep iataCode for backwards compatibility
      criteria.dropoffLocation?.iataCode ??
      criteria.dropoffLocation?.id,
    ...(pickupDateTime && { pickupDateTime }),
    ...(dropoffDateTime && { dropoffDateTime }),
    driverAge: criteria.driverAge,
    residency: criteria.residency ?? criteria.countryCode,
    currencyCode
  }
}

// TODO MPD-1961 We need to keep these old params (eg. "AFA", "AFC".) for backwards compatibility
const partnerParamsMap = {
  airlineFareAmount: "AFA",
  airlineFareCurrency: "AFC",
  ancillaryActivity: "ANC",
  arrivalAirport: "ARR",
  child: "CHL",
  customerPartnerCode: "CPC",
  departureAirport: "DEP",
  fareTypeAndFlex: "FTF",
  flightNumber: "FTN",
  infant: "INF",
  numberOfPassengers: "PAX",
  passengerNameRecord: "PNR",
  partnerCustomerID: "RET",
  partnerLoyaltyAccount: "ALA",
  partnerLoyaltyAccountTier: "ALT",
  supplierLoyaltyAccount: "SLA",
  superPassengerNameRecord: "SNR",
  teenager: "TEE",
  tripType: "TRT",
  corporateCode: "companyCode",
  additionalData: "additionalData",
  agentId: "agentId"
}

function getPartnerParams(criteria) {
  const params = Object.keys(partnerParamsMap)
    .map((key) => {
      const legacyKey = partnerParamsMap[key]
      return [key, criteria[key] ?? criteria[legacyKey]]
    })
    .filter(([, v]) => v != null && v !== "")
  return Object.fromEntries(params)
}

export const createAvailAndPartnerParams = (criteria, currencyCode) => {
  return {
    // Required request parameters
    availParams: getAvailParams(criteria, currencyCode),
    // Optional request parameters
    partnerParams: getPartnerParams(criteria)
  }
}

// Actions
export const doSearchAPI = (config, criteria) => {
  const { currencyCode } = config

  const body = createAvailAndPartnerParams(criteria, currencyCode)

  return fetch(SearchAPIEndpoint, {
    method: "POST",
    headers: getHeadersForAPI(config),
    body: JSON.stringify(body)
  }).then((response) => checkResponse(response))
}

export default {
  createAvailAndPartnerParams,
  doSearchAPI
}
