import { CONFIG } from "@meili/config"

// API routes used for the booking flow.
export const ConfigAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/config`
export const LocationsAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/locations`
export const SearchAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/avail`
export const SearchMapAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/avail/map`
export const CurrencyAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/currency`
export const CostsAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/costs`
export const PreReservationAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/preRes`
export const PreAuthAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/payments/device`
export const ReservationAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/makeRes`
export const RetrieveReservationAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/res`
export const CancelBookingAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/cancel`

// API routes used to get content.
export const vendorImagesAPIEndpoint = `${CONFIG.REACT_APP_CONTENT_API}/images/vendors`

// API routes used for validation
export const LoyaltyValidationAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/loyaltyValidation`

export const EnrolmentCheckAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/payments/enrolment`
export const PaymentPollAPIEndpoint = `${CONFIG.REACT_APP_VEHICLE_API_CONTEXT}/payments`
