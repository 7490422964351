export const responsiveWidthSizes = {
  SMALL: {
    minWidth: 0,
    maxWidth: 640
  },
  MEDIUM: {
    minWidth: 641,
    maxWidth: 1199
  },
  LARGE: {
    minWidth: 1200
  }
}

export const responsiveHeightSizes = {
  SMALL: {
    minHeight: 0,
    maxHeight: 230
  },
  MEDIUM: {
    minHeight: 231,
    maxHeight: 400
  },
  LARGE: {
    minHeight: 401
  }
}

export const widgetResponsiveWidthClasses = {
  SMALL: "widget-width-small",
  MEDIUM: "widget-width-medium",
  LARGE: "widget-width-large"
}

export const widgetResponsiveHeightClasses = {
  SMALL: "widget-height-small",
  MEDIUM: "widget-height-medium",
  LARGE: "widget-height-large"
}

export const getWidgetResponsiveClasses = (widthType, heightType) => {
  let widthClass = "widget-loading"
  let heightClass = ""

  if (widthType) {
    widthClass = widgetResponsiveWidthClasses[widthType]
  }
  if (heightType) {
    heightClass = widgetResponsiveHeightClasses[heightType]
  }
  return [widthClass, heightClass]
}
