import clsx from "clsx"

export const getPartnerStyles = async (ptid, url, style) => {
  if (ptid) {
    const [stylePartnerID, styleTouchPointId] = ptid.split(".")
    const partnerUrl = `${url}/partner_content/${stylePartnerID}/partner.css`
    const touchPointUrl = `${url}/partner_content/${stylePartnerID}/${styleTouchPointId}/style/touchpoint.css`

    const styleUrl = style === "partner" ? partnerUrl : touchPointUrl

    try {
      const styles = await fetch(styleUrl, {
        headers: {
          "Cache-Control": "no-cache"
        }
      })

      if (styles?.status !== 200) {
        const e = new Error("Styles not found")
        e.name = "Styles"

        throw e
      }

      return styles?.text()
    } catch (e) {
      const message =
        style === "partner"
          ? `Custom stylesheet not found for partner - ${stylePartnerID}`
          : `Custom stylesheet not found for partner touchpoint - ${stylePartnerID}.${styleTouchPointId}`
      // eslint-disable-next-line no-console
      console.warn(message)
    }
  }
  return ""
}

export const mliClassNames = (...classes) => {
  const normalisedClasses = clsx(...classes)

  const splitClasses = normalisedClasses.split(" ")

  return splitClasses
    .map((splitClass) =>
      splitClass.startsWith("mli-") ? splitClass : `mli-${splitClass}`
    )
    .join(" ")
}

export default { getPartnerStyles }
